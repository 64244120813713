import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, Observable, of, map, switchMap } from 'rxjs';
import { differenceInMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { CalendarsService } from './calendars.service';
import { CALENDARS_CONFIGURATION } from '../../di/calendars-registration.model';
import {
  CalendarAttendee,
  CalendarAvailability,
  CalendarContact,
  CalendarEvent,
  CalendarGroup,
  CalendarMeInfo,
  CalendarSchedule,
  CalendarsConfiguration,
  CalendarUser,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class GSpaceCalendarService extends CalendarsService {
  statusMap = [
    {
      key: 'reservationPopup.busy',
      value: 'opaque',
    },
    {
      key: 'reservationPopup.free',
      value: 'transparent',
    },
  ];

  constructor(
    @Inject(CALENDARS_CONFIGURATION) private configuration: CalendarsConfiguration,
    private httpClient: HttpClient,
  ) {
    super('gspace');
  }

  getMeInfo(): Observable<CalendarMeInfo> {
    return this.httpClient
      .get('https://people.googleapis.com/v1/people/me?personFields=names')
      .pipe(
        map((response: any) => {
          return {
            id: response.names[0].metadata.source.id,
            jobTitle: '',
            businessPhones: [],
            givenName: response.names[0].givenName,
            surname: response.names[0].familyName,
            mobilePhone: '',
            officeLocation: '',
          };
        }),
      );
  }

  getContactsFromGroup(id: string): Observable<CalendarContact[]> {
    const groupRequest = this.httpClient.get(
      'https://people.googleapis.com/v1/' + id + '?maxMembers=100',
    );

    return groupRequest.pipe(
      switchMap((response: any) => {
        const memberNames = response.memberResourceNames;
        const query = memberNames.join('&resourceNames=');
        return this.httpClient
          .get(
            'https://people.googleapis.com/v1/people:batchGet?personFields=emailAddresses,names&resourceNames=' +
              query,
          )
          .pipe(
            map((x: any) => {
              return x.responses.map((r) => ({
                id: r.person.resourceName,
                displayName: r.person.names[0]?.displayName,
                givenName: r.person.names[0]?.givenName,
                mail: r.person.emailAddresses[0]?.value,
                userPrincipalName: r.person.emailAddresses[0]?.value,
              }));
            }),
          );
      }),
    );
  }

  findContactGroup(input: string): Observable<CalendarGroup[]> {
    if (input == null || input === '') return of([]);
    return this.httpClient.get('https://people.googleapis.com/v1/contactGroups?pageSize=1000').pipe(
      map((response: any) => {
        return response.contactGroups
          .map((g) => ({
            id: g.resourceName,
            displayName: g.formattedName,
          }))
          .filter((x) => x.displayName.toUpperCase().includes(input.toUpperCase()));
      }),
    );
  }

  getIdentifierFromContact(contact: CalendarContact) {
    return contact.mail;
  }

  getImageUrl(identifier: string): Observable<string> {
    return this.httpClient
      .get('https://people.googleapis.com/v1/people/me?personFields=photos')
      .pipe(
        map((response: any) => {
          return response.photos[0].url;
        }),
      );
  }

  findContact(input: string): Observable<CalendarContact[]> {
    if (input === '') return of([]);
    const url =
      'https://people.googleapis.com/v1/people:searchContacts?query=' +
      input +
      '&readMask=names,emailAddresses';
    return this.httpClient.get<any>(url).pipe(
      map((response: { results: any[] }) => {
        return !response.results
          ? []
          : response.results.map((item) => ({
              mail: item.person.emailAddresses[0]?.value,
              displayName: item.person.names[0]?.displayName,
              emailAddresses: [item.person.emailAddresses.map((a) => ({ address: a }))],
              givenName: item.person.names[0]?.givenName,
              jobTitle: '',
              id: item.person.names[0].metadata?.source?.id,
              initials:
                item.person.names[0] != null
                  ? item.person.names[0].familyName?.[0] + item.person.names[0].givenName?.[0]
                  : '@',
              userPrincipalName: item.person.emailAddresses[0].value,
            }));
      }),
    );
  }

  deleteEvent(id: string): Observable<boolean> {
    const url =
      'https://www.googleapis.com/calendar/v3/calendars/primary/events/' + id + '?sendUpdates=all';
    return this.httpClient.delete(url).pipe(map((x: any) => x == null));
  }

  finishEvent(eventId: string): Observable<{ id: string; start: Date; end: Date }> {
    const url =
      'https://www.googleapis.com/calendar/v3/calendars/primary/events/' +
      eventId +
      '?sendUpdates=all';
    const body = {
      end: {
        dateTime: new Date().toLocaleString('sv').replace(' ', 'T'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    return this.httpClient.patch(url, body).pipe(
      map((response: any) => {
        return {
          id: response.id,
          start: response.start.dateTime,
          end: response.end.dateTime,
        };
      }),
    );
  }

  patchEvent(
    visibility: 'public' | 'private',
    status: {
      key: string;
      value: string;
    },
    eventId: string,
    subject: string,
    text: string,
    start: Date,
    end: Date,
    roomName: string,
    roomEmail: string,
    attendees: {
      address: string;
      name: string;
      type: 'required' | 'optional';
    }[],
    onlineMeeting: null | { onlineMeetingProvider: string } = null,
  ): Observable<any> {
    let url =
      'https://www.googleapis.com/calendar/v3/calendars/primary/events/' +
      eventId +
      '?sendUpdates=all';

    if (onlineMeeting?.onlineMeetingProvider != null) {
      url = url + '&conferenceDataVersion=1';
    }
    const body = {
      transparency: status.value,
      summary: subject,
      location: roomName,
      description: text,
      start: {
        dateTime: start.toLocaleString('sv').replace(' ', 'T'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: end.toLocaleString('sv').replace(' ', 'T'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      conferenceData:
        onlineMeeting?.onlineMeetingProvider != null
          ? {
              createRequest: {
                conferenceSolutionKey: {
                  type: 'hangoutsMeet',
                },
                requestId: uuidv4(),
              },
            }
          : undefined,
      attendees: [
        { email: roomEmail, displayName: roomName, optional: false, resource: true },
        ...attendees
          .filter((a) => a.address !== roomEmail)
          .map((a) => ({
            email: a.address,
            displayName: a.name,
            optional: a.type === 'optional',
            resource: false,
          })),
      ],
    };

    return this.httpClient.patch(url, body).pipe(
      map((response: any) => {
        return [{ id: response.id, response: '' }];
      }),
    );
  }

  createEvent(
    visibility: 'public' | 'private',
    status: {
      key: string;
      value: string;
    },
    subject: string,
    text: string,
    start: Date,
    end: Date,
    roomName: string,
    roomEmail: string,
    attendees: {
      address: string;
      name: string;
      type: 'required' | 'optional';
    }[],
    onlineMeeting: null | { onlineMeetingProvider: string } = null,
    webexMeeting: null | {
      body: any;
      url: string;
      code: string;
      password: string;
    } = null,
  ): Observable<{ id: string; response: string; iCalId: string }> {
    if (webexMeeting !== null) {
      const body = webexMeeting.body
        .replaceAll(
          '{meeting-url}',
          "<a href='" + webexMeeting.url + "' target='_blank'>" + webexMeeting.url + '</a>',
        )
        .replaceAll('{meeting-code}', webexMeeting.code)
        .replaceAll('{meeting-password}', webexMeeting.password);
      text = text + '<br/>' + body;
    }
    let url = 'https://www.googleapis.com/calendar/v3/calendars/primary/events?sendUpdates=all';
    if (onlineMeeting?.onlineMeetingProvider != null) {
      url = url + '&conferenceDataVersion=1';
    }
    const body = {
      transparency: status.value,
      summary: subject,
      location: roomName,
      description: text,
      start: {
        dateTime: start.toLocaleString('sv').replace(' ', 'T'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: end.toLocaleString('sv').replace(' ', 'T'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      conferenceData:
        onlineMeeting?.onlineMeetingProvider != null
          ? {
              createRequest: {
                conferenceSolutionKey: {
                  type: 'hangoutsMeet',
                },
                requestId: uuidv4(),
              },
            }
          : undefined,
      attendees: [
        { email: roomEmail, displayName: roomName, optional: false, resource: true },
        ...attendees
          .filter((a) => a.address !== roomEmail)
          .map((a) => ({
            email: a.address,
            displayName: a.name,
            optional: a.type === 'optional',
            resource: false,
          })),
      ],
    };

    return this.httpClient.post(url, body).pipe(
      map((response: any) => {
        return { id: response.id, response: '', iCalId: response.iCalUID };
      }),
    );
  }

  getCollegueInfo(identifier: string): Observable<CalendarUser> {
    throw new Error('Google has not implemented CollegueInfo!');
  }

  // getCalendarViewForColleague(email: string, startFrom: Date, endTo: Date): Observable<{ value: CalendarEvent[] }> {
  //   throw new Error("Google has not implemented Collegues!");
  // }

  getScheduleForEmails(
    emails: string[],
    fromDate: Date,
    toDate: Date,
  ): Observable<CalendarSchedule[]> {
    const uniqueMails = emails.filter(this.onlyUnique);

    if (uniqueMails.length === 0) {
      return of([]);
    }
    const chunks = this.getChunks(uniqueMails, 20);
    const url = 'https://www.googleapis.com/calendar/v3/freeBusy';

    const requests = chunks.map((chunk) => {
      const body = {
        timeMin: fromDate.toISOString(),
        timeMax: toDate.toISOString(),
        timeZone: 'UTC',
        items: chunk.map((x) => ({ id: x })),
      };

      return this.httpClient.post(url, body).pipe(
        map((response: any) => {
          const fifteenCounts = Math.floor(differenceInMinutes(toDate, fromDate) / 15);
          const results: any[] = [];
          const calendarKeys = Object.keys(response.calendars);
          calendarKeys.forEach((key) => {
            const fifteenArray = new Array(fifteenCounts).fill(0);
            const events = response.calendars[key].busy;
            events.forEach((event) => {
              // Alday fix
              if (event.start == null || event.end == null) return;
              const startPosition = Math.round(
                differenceInMinutes(new Date(event.start), fromDate) / 15,
              );
              const endPosition = Math.round(
                differenceInMinutes(new Date(event.end), fromDate) / 15,
              );
              for (let i = startPosition; i < endPosition; i++) {
                fifteenArray[i] = 2;
              }
            });
            results.push({
              scheduleId: key,
              availabilityView: fifteenArray.join(''),
              errors: response.calendars[key].errors,
            });
          });

          return results;
        }),
      );
    });

    return forkJoin(requests).pipe(
      map((responses) => {
        const results = responses.reduce((acc, item) => [...acc, ...item], []);
        return emails.map((email) => {
          const x = results.find((res) => res.scheduleId === email);
          if (x.error != null || x.errors?.length > 0) {
            const data: CalendarSchedule = {
              email: email,
              availabilityView: new Array(
                Math.floor(differenceInMinutes(toDate, fromDate) / 15) + 1,
              )
                .map((_) => CalendarAvailability.tentative)
                .join(''),
              serviceError: x.error?.message != null ? x.error.message : x.error?.responsiveService,
              workingHours: null,
              //todo
              scheduleItems: [],
            };
            return data;
          } else {
            const data: CalendarSchedule = {
              serviceError: null,
              email: email,
              workingHours: null,
              availabilityView: x.availabilityView,
              //todo
              scheduleItems: [],
            };
            return data;
          }
        });
      }),
    );
  }

  availableMeetingProviders(): Observable<string[]> {
    return of([]);
  }

  getCalendarEvents(
    from: Date,
    to: Date,
    identifier: string,
    settings?: any,
  ): Observable<{
    events: CalendarEvent[];
    nextLink: string;
  }> {
    let url: string;
    if (settings?.nextLink == null) {
      url =
        'https://www.googleapis.com/calendar/v3/calendars/primary/events?timeZone=utc&maxResults=30';
      if (from) {
        url = url + '&timeMin=' + from.toISOString();
      }

      if (to) {
        url = url + '&timeMax=' + to.toISOString();
      }
    } else {
      url = settings.nextLink;
    }
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        response.items.forEach((x) => {
          if (x.start?.dateTime == null || x.end?.dateTime == null) {
            console.warn('Problem with google event, missing dateTimes');
            console.warn(x);
          }
        });
        const events: CalendarEvent[] = response.items
          .filter(
            (x) => x.status !== 'cancelled' && x.start?.dateTime != null && x.end?.dateTime != null,
          )
          .map((item) => {
            return {
              isOrganizer: item.organizer.self,
              visibility: 'public',
              status: this.statusMap.find((x) => x.value === item.transparency) ?? {
                key: 'reservationPopup.busy',
                value: 'opaque',
              },
              id: item.id,
              iCalUId: item.iCalUID,
              start: { dateTime: item.start.dateTime, timeZone: 'UTC' },
              end: { dateTime: item.end.dateTime, timeZone: 'UTC' },
              // U google string, takze je nutne udelat korekci pozdeji
              locations: [item.location],
              subject: item.summary,
              bodyHtml: item.description ? item.description : '',
              isAllDay: false,
              onlineMeetingProvider: item.hangoutLink ? item.hangoutLink : null,
              attendees: item.attendees
                ? item.attendees.map(
                    (attendee) =>
                      ({
                        emailAddress: {
                          address: attendee.email,
                          name: attendee.displayName,
                        },
                        resource: attendee.resource,
                        status: {
                          response: attendee.responseStatus,
                        },

                        type: attendee.optional === true ? 'optional' : 'required',
                      }) as CalendarAttendee,
                  )
                : [],
              bodyPreview: '',
              organizer: {
                emailAddress: {
                  name: item.organizer.name,
                  address: item.organizer.email,
                },
              },
            } as CalendarEvent;
          });
        let urlNew =
          'https://www.googleapis.com/calendar/v3/calendars/primary/events?timeZone=utc&maxResults=30';
        if (from) {
          urlNew = urlNew + '&timeMin=' + from.toISOString();
        }

        if (to) {
          urlNew = urlNew + '&timeMax=' + to.toISOString();
        }

        urlNew = urlNew + '&pageToken=' + response.nextPageToken;
        return {
          events: events,
          nextLink: response.nextPageToken ? urlNew : null,
        } as { events: CalendarEvent[]; nextLink: string };
      }),
      map((x) => {
        return {
          ...x,
          events: x.events,
        };
      }),
      map((x) => ({
        ...x,
        events: x.events.map((event) =>
          (({
            isOrganizer,
            status,
            isAllDay,
            attendees,
            end,
            start,
            visibility,
            organizer,
            subject,
            locations,
            bodyPreview,
            id,
            iCalUId,
            type,
            seriesMasterId,
            onlineMeetingProvider,
            bodyHtml = event.bodyHtml,
          }) => ({
            isOrganizer,
            status,
            isAllDay,
            attendees,
            end,
            start,
            visibility,
            organizer,
            subject,
            locations,
            bodyPreview,
            id,
            iCalUId,
            type,
            seriesMasterId,
            onlineMeetingProvider,
            bodyHtml,
          }))(event),
        ),
      })),
    );
  }
}
